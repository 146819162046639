import React, {Component, useState, useEffect} from 'react';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import SideDrawer from './SideDrawer';
import Button from "@material-ui/core/Button";
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import en from './lang/en';
import de from './lang/de';

counterpart.setLocale('en');
counterpart.registerTranslations('en', en);
counterpart.registerTranslations('de', de);
 let state = {
    lang: 'en'
  }
 
  const onLangChange = (e) => {
    this.setState({lang: e.target.value});
    counterpart.setLocale(e.target.value);
  }
  
class Transl extends Component {

  state = {
    lang: 'en'
  }

  onLangChange = (e) => {
    this.setState({lang: e.target.value});
    counterpart.setLocale(e.target.value);
  }
  
 

  render() {
   
    return (
        
       <AppBar
        position="fixed"
        style={{
        background: "#090050",
        padding: " 5px 0px",
        boxShadow: 'none'
      }}
        >
        <Toolbar>
        <div className="header_logo">
        <div className="img_bugpro"></div>
        
         <Translate content="company" className="font_left header_logo_title"/>
         <Translate content="company_inf" component="p" className="font_left header_logo_caption"/>
        
        </div> <div className="lang">
        <Button value={this.state.lang} onClick={this.onLangChange}>
          <option value="en">RU</option>
        </Button>

        <Button value={this.state.lang} onClick={this.onLangChange}>
          <option value="de">PL</option>
        </Button>
         </div>

        <IconButton
          aria-label="Menu"
          color="inherit"          
        >
              <SideDrawer
         
        />
            
          <MenuIcon />
        </IconButton>
        
        </Toolbar>
        </AppBar>
    );
  }


}



function Header(){
     
  let state = {
    lang: 'en'
  }  
    
  const onLangChange = (e) => {
    this.setState({lang: e.target.value});
    counterpart.setLocale(e.target.value);
  } 
  
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [headerShow, setHeaderShow] = useState(false);
  const toggleDrawer = (value) => { setDrawerOpen(value)  }
  useEffect(() => {window.addEventListener("scroll", handleScroll);}, [headerShow]);
  const handleScroll = () =>{(window.scrollY > 0) ? setHeaderShow(true) : setHeaderShow(false);} 
 
  return (      

      
    <AppBar
      position="fixed"
      style={{
        background: headerShow ? "#090050" : "transparent",
        padding: " 5px 0px",
        boxShadow: 'none'
      }}
    >
      <Toolbar>
        <div className="header_logo">
      <div className="img_bugpro"></div>
      
        <div className="font_left header_logo_title"  >       
           БУГ-ПРОЕКТ</div>
          <div className="font_left header_logo_caption">проектирование зданий и сооружений</div>
        </div>
      
      
      
      <div className="lang">
      
      <Button > RU</Button>
      <Button href= "http://pl.bugpro.by/">PL</Button>
        </div>
   {/* <Button onClick={()=>{ alert('alert'); }}>alert</Button>*/}
        <IconButton
          aria-label="Menu"
          color="inherit"
          onClick={() => toggleDrawer(true)}
        >
            
            
          <MenuIcon/>
        </IconButton>
        <SideDrawer
          open={drawerOpen}
          onClose={value => toggleDrawer(value)}
        />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
